import firebase from 'firebase/app'
import 'firebase/auth'
import { makeAutoObservable } from 'mobx'
import AuthService from './../../../shared/services/Auth.service'
import AdminApi from './../../../api/admin-api/admin-api'
import UserProfileService from './../../../shared/services/UserProfile.service'
import UsertypeService from './../../../shared/services/Usertype.service'
import UserService from './../../../shared/services/User.service'
import getRandomPassword from './../../../components/shared/GeneratePassword'
import SubscriptionService from './../../../shared/services/Subscription.service'
import StorageService from './../../../shared/services/Storage.service'
import { getIp } from './../../../shared/utilities/getIp.function'
import {
  TermSetFactory,
  TermFactory,
  RecruitingLeadSource,
} from './../../../shared/factories'
import moment from 'moment'
import { commissionLevels } from '../../../constants/commisionLevels'

const USE_RANDOM_PASS = true

const getLeaderByUplineId = async (userId) =>
  new Promise((resolve, reject) =>
    AdminApi.getLimitedLeadersByUplineId(userId).then((leaders) =>
      resolve(
        (leaders?.data?.data || []).sort((a, b) =>
          `${[a.u_fname, a.u_lname].join(' ').trim()}`.localeCompare(
            `${[b.u_fname, b.u_lname].join(' ').trim()}`,
            'en'
          )
        )
      )
    )
  )

const generatePassword = () =>
  USE_RANDOM_PASS
    ? getRandomPassword.getRandomPassword(8, {
        numbers: true,
        alphabets: true,
        specialCharacters: true,
        upperCase: true,
      })
    : ''

const isAuthenticated = () => UserProfileService.getUserId() > 0

const isAssumed = () => isAuthenticated() && UserProfileService.isAssumed()

const isRecruiter = (usertypeId) =>
  usertypeId
    ? UsertypeService.isA('recruiter-group', usertypeId) ||
      UsertypeService.isA('internal-admin', usertypeId)
    : isAuthenticated() &&
      UserProfileService.isA(['recruiter-group', 'internal-admin'])

const isEnrolled = () =>
  parseInt(UserProfileService.get('completed_enroll')) === 1

const getInitialState = () => {
  if (isAuthenticated() && isAssumed() && !isEnrolled())
    return 'IntakeFormPackageSelection'
  if (isAuthenticated() && !isRecruiter() && !isEnrolled())
    return 'IntakeFormPackageSelection'

  return 'IntakeFormIdentity'
}

class EnrollmentStore {
  // Enrolled/Enrolling User critical props.
  firebaseUid = null
  userId = null
  userIp = null
  usertypeId = 111

  // Intake Form Stages.
  intakeStage = null
  nextIntakeStage = null

  // Boolean flag indicating if in async process to create/enroll acct.
  isCreating = false
  isCompleting = false
  isLoading = false
  // Boolean flag indicating if in async process for payment completed successfully.
  hasPaid = false

  // Intake Form values for acct creation && validity.
  intake = {
    term_set: [],
  }
  termIds = []
  termSetId = null
  validity = {}

  // Recruiter info
  upline = {}
  leaders = [] // If the enrolling user is a team_recruiter, they can select from this list.

  // Intake Form/Enrolling Dependencies.
  TermSets = {}
  commissionLevels = []

  // Billing/subscription data.
  subscriptionData = false

  terms = []
  termSet = []

  constructor() {
    makeAutoObservable(this)
    this.intakeStage = getInitialState()
  }

  setIp = async () => {
    if (!isAssumed()) getIp().then((ip) => (this.userIp = ip))
  }

  reset = () => {
    this.intake = {
      term_set: [],

      // Set Default Commission Type to Agent.
      commission_id: '127',

      // If enabled, set a random password for the agent.
      u_password: USE_RANDOM_PASS ? generatePassword() : '',
    }

    this.usertypeId = 111
    this.intakeStage = getInitialState()
  }

  isAuthenticated = () => this.userId > 0 || isAuthenticated()

  isRecruiter = (usertypeId) => isRecruiter(usertypeId)

  isAssumed = () => isAssumed()

  isEnrolled = () =>
    isAuthenticated() &&
    parseInt(UserProfileService.get('completed_enroll')) === 1

  isValid = (fields) => fields.every((field) => !!this.validity[field]?.isValid)

  isDownlineRecruiter = () =>
    this.isAuthenticated() &&
    this.isAssumed() &&
    this.isRecruiter(this.getAssumed()?.usertype_id)

  getAssumed = () => {
    if (this.isAssumed()) return UserProfileService.getUserDetails()
    return
  }

  setUpline = async (ulogin) => {
    const fetchUplineUser = async () => {
      try {
        return (await AdminApi.getUserDataByULogin(ulogin))?.data
      } catch (ex) {
        console.log(ex)
        return false
      }
    }

    const uplineData = await fetchUplineUser(),
      upline = uplineData && uplineData?.data

    if (upline && typeof upline === 'object') {
      if (!upline?.can_recruit) return 'UPLINE_CANNOT_RECRUIT'
      if (upline?.lock_recruit) return 'UPLINE_LOCKED_RECRUIT'

      this.upline = {
        id: upline?.id,
        u_fname: upline?.u_fname,
        u_lname: upline?.u_lname,
        u_nickname: upline?.u_nickname,
        u_marketing_title: upline?.u_marketing_title,
        u_marketing_phone: upline?.marketing_phone,
        u_picture: upline?.u_picture,
        image_url: uplineData?.imageUrl,
        is_team_recruiter: upline?.is_team_recruiter,
        team_logo: upline?.team_logo,
        usertype_id: upline?.usertype_id
      }
      console.log('this.upline', this.upline)
      // fetch the downline leaders, starting with upline, that can be used for team assignment.
      if (upline?.is_team_recruiter && upline?.u_upline_id)
        getLeaderByUplineId(upline.u_upline_id).then(
          (leaders) => (this.leaders = leaders)
        )

      return true
    }

    return 'INVALID_UPLINE_USER'
  }

  fetchRecruitingLeadSources = async () => {
    const fetch = async () => {
      try {
        return await RecruitingLeadSource.search({
          search: {},
          pagination: false,
        })
      } catch (ex) {
        console.error('Failed to load enrollment terms.', ex)
      }
      return []
    }

    const Response = await Promise.all([fetch()])

    let Last = null,
      RecruitingLeadSources = Response.shift()
    RecruitingLeadSources = (RecruitingLeadSources || [])
      .sort((a, b) =>
        `${a?.get('ls_name') || ''}`.localeCompare(
          `${b?.get('ls_name') || ''}`,
          'en'
        )
      )
      .filter((a) => {
        if (a?.id() && parseInt(a.id()) === 1) {
          Last = a
          return false
        }
        return true
      })
    if (Last) RecruitingLeadSources.push(Last)

    this.RecruitingLeadSources = RecruitingLeadSources
  }

  fetchTerms = async () => {
    const fetchTermDocs = async () => {
      try {
        return await TermFactory.search({
          search: { is_enroll: 1, active: 1 },
          pagination: false,
        })
      } catch (ex) {
        console.error('Failed to load enrollment terms.', ex)
      }
    }

    const fetchTermSets = async () => {
      try {
        return await TermSetFactory.search({
          search: { usertype_id: [91] },
          pagination: false,
        })
      } catch (ex) {
        console.error('Failed to load enrollment term sets.', ex)
      }
    }

    const Response = await Promise.all([fetchTermDocs(), fetchTermSets()]),
      Terms = Response.shift(),
      TermSets = (Response.shift() || []).filter((TermSet, idx) => idx === 0)

    TermSets.forEach((TermSet) => {
      TermSet.parseTermStmt({ Terms })
      this.TermSets[TermSet.get('usertype_id')] = TermSet
    })
  }

  fetchCommissionLevels = async () => {
    let commissionLevels = []
    try {
      commissionLevels = (await AdminApi.getCommissionLevelList())?.data?.data
    } catch (ex) {}

    this.commissionLevels = commissionLevels
  }

  enroll = async (verify) => {
    const enrollmentPayload = {
      u_fname: this.intake.u_fname.trim(),
      u_lname: this.intake.u_lname.trim(),
      u_email: this.intake.u_email.trim(),
      u_password: this.intake.u_password,
      u_address1: this.intake.u_address1,
      u_city: this.intake.u_city,
      u_zip: this.intake.u_zip,
      u_phone: this.intake.u_phone.trim(),
      u_birthday: moment(this.intake.u_birthday).format('YYYY-MM-DD'),
      u_state: this.intake.u_state,
      commission_id: this.intake.commission_id,
      usertype_id: 111,
      u_enroll_source: this.intake.u_enroll_source,
      u_enroll_source_detail: this.intake.u_enroll_source_detail,
      u_upline_id: this.upline.id,
      upline_override: this.intake.upline_override,
      npn: this.intake.npn.trim(),
    }

    if (verify === true)
      await UserService.enroll(this.upline.id, enrollmentPayload, {
        verify: true,
      })

    const getFirebaseUid = async () => {
      if (this.firebaseUid) return this.firebaseUid

      let firebaseUser = await this.registerFirebaseUser(
        this.intake.u_email,
        this.intake.u_password
      )

      if (firebaseUser && firebaseUser?.user?.uid) {
        this.firebaseUid = firebaseUser?.user?.uid
        return firebaseUser.user.uid
      }
    }

    const fUid = await getFirebaseUid()

    return (
      (await UserService.enroll(this.upline.id, {
        ...enrollmentPayload,
        firebase_uid: fUid,
      })) || false
    )
  }

  registerFirebaseUser = async (email, password) =>
    await firebase.auth().createUserWithEmailAndPassword(email, password)

  authenticateNewUser = async (email, password) => {
    let result
    try {
      result = await AuthService.login(email, password)
      this.userId = UserProfileService.getUserId()
    } catch (ex) {
      result = `${ex}`
    }

    if (result && !isNaN(result?.usertype_id)) return this.userId > 0

    throw Error(
      result && typeof result === 'string'
        ? result
        : 'Unable to login new account.  Please check your email for login details.  Contact support if errors persist.'
    )
  }

  authenticateDownlineUser = async (userId) => {
    await AuthService.assumeUser(userId)
    this.userId = userId
    return this.userId > 0
  }

  authenticate = async (newUser) => {
    if (newUser?.id && newUser.id === newUser?.auth_id) {
      // login to own account.
      return this.authenticateNewUser(
        this.intake.u_email,
        this.intake.u_password
      )
    } else if (newUser?.id && newUser?.auth_id) {
      // login to downline account.
      return this.authenticateDownlineUser(newUser.id)
    }

    // advance stage.
    // no longer permitted to go back.
    return false
  }

  completeEnrollment = async (usertypeId) => {
    this.isCompleting = true
    UserProfileService.set('completed_enroll', 1)
    UserProfileService.set('usertype_id', usertypeId)

    if (!this.userIp && !isAssumed()) this.userIp = await getIp()

    return new Promise((resolve, reject) => {
      UserService.complete(UserProfileService.getUserId(), {
        usertypeId: this.usertypeId,
        termset_id: this.intake.termset_id,
        termset_signature: this.intake.term_set_signature,
        term_ids: this.intake.term_ids,
        subscription_id: this.subscriptionId,
        ...(this.userIp && !isAssumed() ? { accepted_ip: this.userIp } : {}),
      }).then(
        (res) => {
          if (UserProfileService.isAssumed())
            StorageService.set('prev_url', '/dashboard')

          this.isCompleting = false
          this.reset()
          resolve(true)
        },
        (err) => {
          UserProfileService.set('completed_enroll', 0)
          UserProfileService.set('usertype_id', 111)

          this.isCompleting = false
          reject(err)
        }
      )
    })
  }

  initiateSubscription = async () => {
    if (!this.userIp && !isAssumed()) this.userIp = await getIp()

    try {
      await SubscriptionService.initiateSubscription({
        userId: this.userId || UserProfileService.getUserId(),
        usertypeId: this.usertypeId || 91,
        subClass: 'sig_agent',
        ...(this.userIp && !isAssumed() ? { userIp: this.userIp } : {}),
      })
    } catch (ex) {}

    if (SubscriptionService.getSubscriptionData()?.subscription_id)
      this.subscriptionData = SubscriptionService.getSubscriptionData()
  }
}

export default new EnrollmentStore()
